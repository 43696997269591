import { render, staticRenderFns } from "./coverSettings.vue?vue&type=template&id=645e65a1&scoped=true&"
import script from "./coverSettings.vue?vue&type=script&lang=js&"
export * from "./coverSettings.vue?vue&type=script&lang=js&"
import style0 from "./coverSettings.vue?vue&type=style&index=0&id=645e65a1&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "645e65a1",
  null
  
)

component.options.__file = "coverSettings.vue"
export default component.exports